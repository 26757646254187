<template>
    <div class="entfreespace_container_m blur">
        <div class="entfreespace_container_m_wrap">
            <img
                src="/media/img/close_btn.png"
                @click="$emit('close-modal')"
                class="entfreespace_container_m_close_img"
                alt="close_btn"
            />
            <h1 class="title_lg font_white entfreespace_container_m_title" v-html="nonusespacetext"></h1>
            <div class="subscribe_btn_wrap">
                <button class="entfreespace_subscribe_btn current_subscribe" @click="onSubscribeBtn">{{ spaceaddtext }}</button>
                <button
                    class="entfreespace_subscribe_btn newspace_subscribe"
                    v-if="usertype === 3"
                    @click="$emit('new-spacesubscribe')"
                >
                    새로운 스페이스 구독하기
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'NonUserSpace',
  emits: ['close-modal', 'currentspace-subscribe', 'new-spacesubscribe'],
  props: {
    usertype: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {
    onSubscribeBtn () {
      if (this.usertype === 3) {
        this.$emit('currentspace-subscribe');
      } else {
        this.$emit('new-spacesubscribe');
      }
    }
  },
  computed: {
    nonusespacetext () {
      if (this.usertype === 3) {
        return `스페이스 구독 후 <br>이용가능합니다.`;
      }
      return `스페이스 등록 후<br>이용가능합니다.`;
    },
    spaceaddtext () {
      if (this.usertype === 3) {
        return `현재 스페이스 구독하기`;
      }
      return `스페이스 등록하기`;
    }
  }
};
</script>
<style scoped src="@/assets/css/player/endfreespace.css"></style>
